import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import Swal from 'sweetalert2';
import { toggleLoader, validateCampo } from "src/app/shared/helpers/functions-helper.service";
import { LoaderService } from "src/app/shared/services/Loader.service";
import { NavService } from '../../shared/services/nav.service';

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

	public show: boolean = false;
	public loginForm: FormGroup;
	public errorMessage: any;
	validateCampo = validateCampo;

	constructor(
		private fb: FormBuilder,
		public router: Router,
		private authService: AuthService,
        private navService: NavService,
        private loaderService: LoaderService
	) { }

	//ngOnInit
	ngOnInit() {
        this.loginForm = this.fb.group({
          email: ["", [Validators.required, Validators.email]],
          password: ["", Validators.required],
        });
	}

	showPassword() {
		this.show = !this.show;
	}

	// Simple Login
	login() {
        if (this.loginForm.invalid) {
            Swal.fire("Error", "Por favor, completa correctamente todos los campos.", "error");
            return;
        }
        toggleLoader(this.loaderService, true); 
		const email = this.loginForm.value.email;
		const password = this.loginForm.value.password;

		this.authService.login(email, password).subscribe(
			(response) => {
                toggleLoader(this.loaderService, false); 
                if(response.ok) {
                    this.authService.setUser(response.data);
                    this.authService.setToken(response.token);
                    const rol = response.data.role_id;
                    this.navService.updateMenu(rol);

                    //redirigir de acuerdo al rol
                    if ([1, 2, 3].includes(rol)) {
                        // Redirigir a panel/inicio si el rol es 1, 2 o 3
                        this.router.navigate(['panel/inicio']);
                    } else if ([4, 6, 7, 8].includes(rol)) {
                        // Redirigir a proveedores/panel/inicio si el rol es 4, 6, 7 u 8
                        this.router.navigate(['proveedores/panel/inicio']);
                    } else if (rol === 5) {
                        // Redirigir a clientes/inicio si el rol es 9
                        this.router.navigate(['clientes/inicio']);
                    } else {
                        alert('rol no comtemplado');
                        // Opcionalmente, manejar el caso cuando el rol no coincide con ninguno de los anteriores
                    }
                } else {
                    Swal.fire("Error", response.message, "error");
                }
			},
			(error) => {
                toggleLoader(this.loaderService, false); 
				Swal.fire("Error", error.error.message, "error");
			}
		);
	}
}
