<div class="loader-wrapper" *ngIf="loaderService.isLoading$ | async">
  <div class="loader">
    <img
      src="../../../../assets/images/logo/logo.gif"
      width="300"
      alt="Loader"
    />
    <div class="text-center text-white">Cargando...</div>
    <!-- <div class="loader-bar"></div>
     <div class="loader-bar"></div>
     <div class="loader-bar"></div>
     <div class="loader-bar"></div>
     <div class="loader-bar"></div>
     <div class="loader-ball"></div> -->
  </div>
</div>
<!-- [class.loderhide]="!show"   -->
