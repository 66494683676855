import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerPaginatedInterface, CustomerResponseInterface } from '../interfaces/customer-interface';
import { CustomerHistorialResponseInterface } from './customer-historial-interface';
import { Subject } from 'rxjs';
import { CustomerShowHistorialResponseInterface } from '../interfaces/customer-show.interface';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  /*************
   *  variables**
   *************/
  private baseUrl: string = environment.apiUrl + '/clientes';
  private _refreshUsers$ = new Subject<void>();


  constructor(private http: HttpClient) {}

/*************
  *  getters****
  *************/
    get _refreshUsers() {
        return this._refreshUsers$;
    }

  /*************
   *  metodos****
   *************/
  getCustomers(page: number) {
    const url = page==0 ?  `${this.baseUrl}` : `${this.baseUrl}/page/${page-1}`;
    return this.http.get<CustomerPaginatedInterface>(url);
  }


  //traer información del registro
  getCustomerInfo(id: number) {
    return this.http.get<CustomerResponseInterface>(`${this.baseUrl}/${id}`);
  }
  //traer información del registro
  getCustomerInfoHistorial(identification: string) {
    const url = `${this.baseUrl}/historial-tienda/cedula/${identification}`;
    return this.http.get<CustomerHistorialResponseInterface>(url);
  }

  //getCustomerHistorial
  getCustomerHistorial(id: number) {
    const url =  `${this.baseUrl}/historial/${id}`;
    return this.http.get<CustomerShowHistorialResponseInterface>(url);
  }

  getCustomersFilter(formData:any) {  
    const params = new HttpParams({ fromObject: formData });
    const url =  `${this.baseUrl}/buscar`;
    return this.http.get<CustomerPaginatedInterface>(url, { params });
  }
}
