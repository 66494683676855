<div class="container-fluid">
   <div class="page-title">
     <div class="row">
       <div class="col-sm-6">
         <h3>{{title}}</h3>
       </div>
       <div class="col-sm-6">
         <ol class="breadcrumb">
           <li class="breadcrumb-item">
             <a [routerLink]="'/dashboard/default'">
              <app-feather-icon [icon]="'home'"></app-feather-icon>
             </a>
           </li>
           <li class="breadcrumb-item" *ngFor="let item of items; let last = last" [ngClass]="{ 'active': last }">
              <a *ngIf="!last" [routerLink]="item.url">{{ item.label }}</a>
              <span *ngIf="last">{{ item.label }}</span>
            </li>
         </ol>
       </div>
     </div>
   </div>
 </div>