import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/login/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const token = this.authService.getToken();
  
    if (request.url.endsWith('/auth')) {
        // Si la URL termina con '/auth', la petición se maneja sin modificación
        return next.handle(request);
    }

    if (token) {
        request = request.clone({
            setHeaders: {
            Authorization: `Bearer ${token}`
            }
        });
    }
    
    return next.handle(request);
  }
}
 