<ul *ngIf="paginas.length > 0" class="pagination mt-3 justify-content-end">
  <li class="page-item" *ngIf="paginador.number > 0">
    <a class="page-link" [routerLink]="[route, paginador.number - 1]"
      >&laquo;</a
    >
  </li>

  <li [ngClass]="paginador.first ? 'disabled page-item' : 'page-item'">
    <a class="page-link" [routerLink]="[route, 0]">Primera</a>
  </li>

  <li
    *ngFor="let pagina of paginas"
    [ngClass]="
      pagina - 1 == paginador.number ? 'page-item active' : 'page-item'
    "
  >
    <span class="page-link" *ngIf="pagina - 1 == paginador.number">{{
      pagina
    }}</span>
    <a
      *ngIf="pagina - 1 != paginador.number"
      class="page-link"
      [routerLink]="[route, pagina - 1]"
      >{{ pagina }}</a
    >
  </li>
  <li [ngClass]="paginador.last ? ' disabled page-item' : 'page-item'">
    <a class="page-link" [routerLink]="[route, paginador.totalPages - 1]"
      >Última</a
    >
  </li>

  <li class="page-item" *ngIf="paginador.number < paginador.totalPages - 1">
    <a class="page-link" [routerLink]="[route, paginador.number + 1]"
      >&raquo;</a
    >
  </li>
</ul>
