import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreditStateResponseInterface } from "src/app/administrative/credits/interfaces/credits-state.interface";
import { ArlInterface } from "src/app/administrative/parameters/submodules/arl/interfaces/ArlInterface";
import { EpsInterface } from "src/app/administrative/parameters/submodules/eps/interfaces/EpsInterface";
import { PayFrequiencieResponseInterface } from "src/app/administrative/parameters/submodules/pays-frequencies/interfaces/pays-frequiencies-interface";
import { PensionFundInterface } from "src/app/administrative/parameters/submodules/pension-fund/interfaces/PensionFundInterface";
import { PositionInterface } from "src/app/administrative/parameters/submodules/positions/interfaces/PositionInterface";
import { SeveranceFundInterface } from "src/app/administrative/parameters/submodules/severance-fund/interfaces/SeveranceFundInterface";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class ParemetersService {
	private readonly baseUrl = environment.apiUrl;

	constructor(private http: HttpClient) {}

	getAllies() {
		return this.http.get(`${this.baseUrl}/aliados`);
	}

	getCities() {
		return this.http.get(`${this.baseUrl}/ciudades`);
	}
	getCitiesByDepartments(departmentId: number) {
		return this.http.get(
			`${this.baseUrl}/ciudades/departamento/${departmentId}`
		);
	}

	getDepartments() {
		return this.http.get(`${this.baseUrl}/departamentos`);
	}

	getBanks() {
		return this.http.get(`${this.baseUrl}/bancos`);
	}

	getArls() {
		return this.http.get<ArlInterface[]>(`${this.baseUrl}/arl`);
	}

	getEps() {
		return this.http.get<EpsInterface[]>(`${this.baseUrl}/eps`);
	}

	getPensionFunds() {
		return this.http.get<PensionFundInterface[]>(`${this.baseUrl}/eps`);
	}

	getSeveranceFunds() {
		return this.http.get<SeveranceFundInterface[]>(
			`${this.baseUrl}/pensiones`
		);
	}

	getPositions() {
		return this.http.get<PositionInterface[]>(`${this.baseUrl}/cargos`);
	}

	getPaysFrequencies() {
		return this.http.get<PayFrequiencieResponseInterface>(
			`${this.baseUrl}/frecuencias-pago`
		);
	}

    getCreditsStates() {
		return this.http.get<CreditStateResponseInterface>(
			`${this.baseUrl}/creditos/estados`
		);
	}
}
