import { Routes } from "@angular/router";

export const full: Routes = [
	{
		path: "error-page",
		loadChildren: () =>
			import("../../pages/error-page/error-page.module").then(
				(m) => m.ErrorPageModule
			),
	},
	{
		path: "coming-soon",
		loadChildren: () =>
			import("../../pages/coming-soon/coming-soon.module").then(
				(m) => m.ComingSoonModule
			),
	},
];
