<div class="modal-header">
  <h4 class="modal-title">
    {{ title }} Solicitud de credito #{{ creditRequestId }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>
<div class="modal-body">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="tabActiva === 'informacionPersonal'"
        (click)="cambiarPestana('informacionPersonal')"
      >
        Información Personal
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="tabActiva === 'documentosAsociados'"
        (click)="cambiarPestana('documentosAsociados')"
      >
        Documentos Asociados
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        [class.active]="tabActiva === 'historialCreditos'"
        (click)="cambiarPestana('historialCreditos')"
      >
        Historial de Créditos
      </a>
    </li>
  </ul>

  <div class="tab-content">
    <div
      [class]="
        tabActiva === 'informacionPersonal'
          ? 'tab-pane show active'
          : 'tab-pane'
      "
    >
      <div class="row my-4">
        <div class="col-12">
          <h5>Solicitud diligenciada por el cliente</h5>
        </div>
      </div>
      <!-- Campos de información personal -->
      <form>
        <div class="row">
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>ID Usuario</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.id"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>Nit</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.nit"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>Nombre de la empresa</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.buss_name"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>Ciudad</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.city_birth"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>Dirección de la Empresa</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.buss_name"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="form-group">
              <label>Registrado en Cámara de Comercio</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.cci"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>Nombres</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.name"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Apellidos</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.last_name"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Tipo de identificación</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.identification_type"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mt-2">
              <label>Número de identificación</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.identification"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mt-2">
              <label>Número de Celular</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.customer_phones[0].phone_number"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mt-2">
              <label>Número de Celular 2</label>
              <input
                type="text"
                class="form-control"
                [value]="
                  customerInfo.customer_phones[1] &&
                  customerInfo.customer_phones[1].phone_number
                    ? customerInfo.customer_phones[1].phone_number
                    : ''
                "
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mt-2">
              <label>Email</label>
              <input
                type="text"
                class="form-control"
                [value]="customerInfo.email"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Dirección</label>
              <input
                type="text"
                class="form-control mt-2"
                [value]="customerInfo.customer_addresses[0].address"
                [disabled]="datosDeshabilitados"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 text-end">
            <button
              type="button"
              class="btn btn-primary me-2"
              (click)="habilitarDatos()"
            >
              <i class="fas fa-save ml-2"></i> Editar
            </button>
            <button
              type="button"
              class="btn btn-success"
              (click)="ActualizarDatos()"
            >
              <i class="fas fa-save ml-2"></i> Actualizar
            </button>
          </div>
        </div>
      </form>

      <!-- FOTOS -->
      <hr />
      <div class="row my-2">
        <div class="col-12">
          <h5>FOTOS CLIENTE</h5>
        </div>
      </div>
      <div>
        <a
          [href]="customerInfo.document_file_up"
          target="_blank"
          class="btn btn-danger me-2"
        >
          <i class="fas fa-camera"></i>
          Ver foto frontal
        </a>
        <a
          [href]="customerInfo.document_file_down"
          target="_blank"
          class="btn btn-danger me-2"
        >
          <i class="fas fa-camera"></i>
          Ver foto reverso
        </a>
        <a
          [href]="customerInfo.image_file"
          target="_blank"
          class="btn btn-danger"
        >
          <i class="fas fa-camera"></i>
          Ver selfie
        </a>
      </div>

      <!-- NOTAS -->
      <hr />
      <div class="row my-2">
        <div class="col-12">
          <h5>NOTAS</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <textarea
              class="form-control"
              [(ngModel)]="textareaContent"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-2 mt-3 text-end">
          <button type="button" class="btn btn-success" (click)="saveNotes()">
            <i class="fas fa-save ml-2"></i> Guardar
          </button>
        </div>
      </div>
      <hr />
      <div class="card">
        <div class="card-header pb-0">
          <div class="media">
            <div class="media-body">
              <h5 class="mb-0">Notas agregadas:</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="activity-media">
            <div class="media" *ngFor="let nota of notasAgregadas">
              <div class="recent-circle bg-primary"></div>
              <div class="media-body">
                <h6 class="font-roboto mb-0">{{ nota.note }}</h6>
                <span>
                  <i class="fas fa-clock"></i>
                  <span class="font-roboto">
                    Nota dejada el {{ nota.created }} por {{ nota.user }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- DOCUMENTOS -->
    <div
      [class]="
        tabActiva === 'documentosAsociados'
          ? 'tab-pane show active'
          : 'tab-pane'
      "
    >
      <div class="row my-4">
        <div class="col-12">
          <h5>DOCUMENTOS ASOCIADOS</h5>
        </div>
      </div>
      <div class="form-group justify-content-between d-flex">
        <input type="file" #fileInput class="form-control-file" />
        <button
          type="button"
          class="btn btn-primary"
          (click)="subirDocumento(fileInput)"
        >
          Subir documento
        </button>
      </div>
      <div>
        <!-- Historial de documentos subidos -->
        <hr />
        <div>
          <h6>Documentos agregados:</h6>
          <ul class="files-content">
            <li *ngFor="let document of documentsList; let i = index" class="ng-star-inserted">
              <div class="d-flex align-items-center files-list">
                <div class="flex-shrink-0 file-left">
                  <i class="f-22 fa fa-file-excel-o font-success"></i>
                </div>
                <div class="flex-grow-1 ms-3">
                  <h6 class="mb-1">Documento # {{ i + 1 }}</h6> 
                  <p>Fecha creación : <small>{{ document.created | date:'yyyy MM dd' }}</small></p> 
                </div>
              </div>
            </li>
          </ul>
          
        </div>
      </div>
    </div>

    <!-- HITORIAL CREDITOS -->

    <div
      [class]="
        tabActiva === 'historialCreditos' ? 'tab-pane show active' : 'tab-pane'
      "
    >
      <table class="table mt-2">
        <thead>
          <tr>
            <th># Obligación</th>
            <th>Fecha inicio</th>
            <th>Fecha fin</th>
            <th>Valor Retirado</th>
            <th># cuotas</th>
            <th>Valor cuota</th>
            <th>Días mora</th>
            <th>Valor mora</th>
            <th>Saldo sin mora</th>
            <th>Saldo con mora</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <!-- si hay datos -->
          <ng-template
            *ngIf="
              historyCreditsCustomerList &&
              historyCreditsCustomerList.length > 0
            "
          >
            <tr *ngFor="let historyCredits of historyCreditsCustomerList">
              <td>{{ historyCredits.code_pay }}</td>
              <td>{{ historyCredits.created | date : 'yyyy-MM-dd' }}</td>
              <td>{{ historyCredits.deadline | date : 'yyyy-MM-dd' }}</td>
              <td>
                {{
                  historyCredits.value_credit
                    | currency : '$' : 'symbol' : '1.0-0'
                }}
              </td>
              <th>{{ historyCredits.number_fee }}</th>
              <td>
                {{
                  historyCredits.quota_value
                    | currency : '$' : 'symbol' : '1.0-0'
                }}
              </td>
              <td>
                {{
                  historyCredits.quota_value
                    | currency : '$' : 'symbol' : '1.0-0'
                }}
              </td>
              <td>{{ historyCredits.days_late }}</td>
              <td>
                {{
                  historyCredits.remaining_balance
                    | currency : '$' : 'symbol' : '1.0-0'
                }}
              </td>
              <td>
                {{
                  historyCredits.total_pay_credit
                    | currency : '$' : 'symbol' : '1.0-0'
                }}
              </td>
              <td>
                <app-state-badge
                  [state]="historyCredits.state"
                ></app-state-badge>
              </td>
            </tr>
          </ng-template>

          <!-- si no hay datos -->
          <ng-template
            *ngIf="
              !(
                historyCreditsCustomerList &&
                historyCreditsCustomerList.length > 0
              )
            "
          >
            <tr>
              <td colspan="11" class="text-center">
                <app-msj-no-registers />
              </td>
            </tr>
          </ng-template>
          <!-- Agregar más filas según sea necesario -->
        </tbody>
      </table>
    </div>
  </div>
</div>
