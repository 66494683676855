import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, tap } from "rxjs";
import { environment } from "src/environments/environment";
import {
	CommerceInterface,
	CommercePaginatedInterface,
	CommerceResponseInterface,
} from "../interfaces/commerce-interface";
import { UserInterface, UserResponseInterface } from "../../users/interfaces/users.interface";
import { CommerceDetailResponseInterface } from "../interfaces/commerce-detail.interface";
import {  ShopListResponseInterface } from "./shop-list.interface";

@Injectable({
	providedIn: "root",
})
export class CommercesService {
	//variables
	closeResult: string;
	baseUrl = environment.apiUrl + "/sucursales";
	//refrescar tabla al guardar o actuailzar
	private _refreshShopsCommerces$ = new Subject<void>();

	constructor(private http: HttpClient) {}

	/*************
	 *  getters****
	 *************/
	get _refreshShopsCommerces() {
		return this._refreshShopsCommerces$;
	}

	//funciones
    getShops(): Observable<ShopListResponseInterface> {
        const url = environment.apiUrl + "/proveedores/listado";

		return this.http.get<ShopListResponseInterface>(url);
	}

    getShopCommerces(): Observable<CommercePaginatedInterface> {
        const url = environment.apiUrl + "/sucursales/page";
		return this.http.get<CommercePaginatedInterface>(url);
	}

	getShopCommercesList(): Observable<CommerceResponseInterface> {
		return this.http.get<CommerceResponseInterface>(this.baseUrl);
	}

	//guardar registro
	storeShopCommerce(shopcommerce: CommerceInterface) {
		return this.http
			.post<CommerceResponseInterface>(this.baseUrl, shopcommerce)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	//traer información del registro
	showShopCommerce(id: number) {
		return this.http.get<CommerceResponseInterface>(
			`${this.baseUrl}/ver/${id}`
		);
	}

	//actualizar registro
	updateShopCommerce(
		id: number,
		shopcommerce: CommerceInterface
	): Observable<any> {
		return this.http
			.put<CommerceResponseInterface>(
				`${this.baseUrl}/${id}`,
				shopcommerce
			)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	//eliminar registro
	deleteShopCommerce(id: number): Observable<any> {
		return this.http
			.delete<CommerceResponseInterface>(`${this.baseUrl}/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	//habilitar registro
	enableShopCommerce(id: number): Observable<any> {
		return this.http
			.get<CommerceInterface>(`${this.baseUrl}/habilitar/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	//desabilitar registro
	disableShopCommerce(id: number): Observable<any> {
		return this.http
			.get<CommerceInterface>(`${this.baseUrl}/desabilitar/${id}`)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

	registerUserShopCommerce(shopCommerceId:number,userShopCommerce: UserInterface) {
		return this.http
			.post<UserResponseInterface>(`${this.baseUrl}/guardar-usuario/${shopCommerceId}`, userShopCommerce)
			.pipe(
				tap(() => {
					this._refreshShopsCommerces$.next();
				})
			);
	}

    //getDetailCommerce
    getDetailCommerce(id: number) {
        return this.http.get<CommerceDetailResponseInterface>(`${this.baseUrl}/ver/${id}`);
    }
}
