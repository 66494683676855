<div class="container-fluid p-0">
  <div class="row login-header">
    <div class="col-5 col-md-9 ps-3 ps-md-5 pt-3">
      <img src="../../../assets/images/logo/logo-registro.png" alt="Logo" width="120" />
    </div>
    <div class="col-7 col-md-3 text-md-end pt-2">
      <a href="auth/registro" class="btn btn-crearCuenta my-4 m-md-4">Crear mi cuenta</a>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <form class="theme-form login-form" [formGroup]="loginForm">
          <h4>Login</h4>
          <h6>Bienvenido al inicio de sesión de tu cuenta.</h6>
          <div class="form-group">
            <label>Correo electrónico</label>
            <div class="input-group">
              <span class="input-group-text"><i class="icon-email"></i></span>
              <input class="form-control" type="email" formControlName="email" />
              <!-- <p class="text-danger" *ngIf="validateCampo(loginForm, 'name') as error">
                {{ error }}
              </p> -->
            </div>
          </div>
          <div class="form-group">
            <label>Password</label>
            <div class="input-group">
              <span class="input-group-text"><i class="icon-lock"></i></span>
              <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" />
              <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                <span class="show"></span>
              </div>
              <div class="show-hide" (click)="showPassword()" *ngIf="show">
                <span class="Hide"></span>
              </div>
              <!-- <p class="text-danger" *ngIf="validateCampo(loginForm, 'password') as error">
                {{ error }}
              </p> -->
            </div>
          </div>
          <div class="form-group">
            <button 
              class="btn btn-primary btn-block w-100"  
              (click)="login()"
              type="button">
              <span>Iniciar sesión</span>
            </button>
          </div>
          <div class="form-group">
            <a class="link" [routerLink]="'/authentication/forgot-password'">¿Olvidaste tu contraseña?</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
