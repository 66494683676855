import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditRequestService } from 'src/app/administrative/credits-requests/services/credit-request.service';
import { toggleLoader } from 'src/app/shared/helpers/functions-helper.service';
import { LoaderService } from 'src/app/shared/services/Loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-credits-requests-comments',
  templateUrl: './credits-requests-comments.component.html',
  styleUrls: ['./credits-requests-comments.component.css'],
})
export class CreditsRequestsCommentsComponent {
  @Input() creditRequestId: number; // Propiedad para almacenar el id de la solicitud de crédito

  textareaContent: string; // Propiedad para almacenar el contenido del textarea
  selectReason: string; // Propiedad para almacenar el contenido de Selecciona un motivo
  listComents: any[] = []; // Propiedad para almacenar la lista de comentarios

  constructor(
    public activeModal: NgbActiveModal,
    private CreditRequestService: CreditRequestService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.getCommentsRequest();
  }

  saveComments() {
    if(this.textareaContent == undefined || this.textareaContent == ''
        && this.selectReason == undefined || this.selectReason == ''){
        Swal.fire('Error', 'Por favor completa todos los campos.', 'warning');
        return ;
    }

    toggleLoader(this.loaderService, true);
    const customerId = this.creditRequestId;
    const valueComment = this.textareaContent;
    const selectReason = this.selectReason;
    //const note = this.customerForm?.get('note').value;
    console.log('Contenido del textarea:', valueComment);
    console.log('Contenido del selectReason:', selectReason);
    this.CreditRequestService.saveCommentsCreditRequest(customerId, valueComment, selectReason)
    .subscribe((response) => {
        if (response.ok) {
        Swal.fire('Éxito', 'Se agregó un nuevo comentario.', 'success');
        toggleLoader(this.loaderService, false);
        this.getCommentsRequest();
        this.textareaContent = '';
        } else {
        Swal.fire('Error', 'No se guardó el comentario. Intenta nuevamente.', 'error');
        toggleLoader(this.loaderService, false);
        }
    }, (error) => {
        Swal.fire('Error', 'Ocurrió un error al guardar el comentario. Inténtalo nuevamente.', 'error');
        toggleLoader(this.loaderService, false);
        console.error(error); // Imprime el error en la consola para fines de depuración
    });

  }

  getCommentsRequest() {
    toggleLoader(this.loaderService, true);
    const customerId = this.creditRequestId;
    this.CreditRequestService.getCommentsCreditRequest(customerId).subscribe(
      (response) => {
        if (response.ok) {
          this.listComents = response.data;
          console.log('Lista de comentarios:', this.listComents);
          toggleLoader(this.loaderService, false);
        } else {
        }
      }
    );
    toggleLoader(this.loaderService, false);
  }
}
