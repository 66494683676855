<app-breadcrumb [title]="'Inicio'" [items]="['Dashboard']" [active_item]="'Default'"></app-breadcrumb>
<div class="card"></div>
<div class="container-fluid default-dash">
  <div class="row">
    <div class="col-md-4 col-xl-3">
      <div class="card bg-c-blue order-card">
        <div class="card-block">
          <h6 class="m-b-20">Creditos retirados</h6>
          <h2 class="text-right"><i class="fa fa-cart-plus f-left"></i><span class="p-3">$0</span></h2>
          <p class="m-b-0">Vér detalle<span class="f-right"></span></p>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-xl-3">
      <div class="card bg-c-green order-card">
        <div class="card-block">
          <h6 class="m-b-20">Créditos pendientes</h6>
          <h2 class="text-right">
            <i class="fa fa-rocket f-left"></i>
            <span class="p-3">$0</span>
        </h2>
          <p class="m-b-0">Vér detalle<span class="f-right"></span></p>
          <!-- <p class="m-b-0">Completed Orders<span class="f-right">351</span></p> -->
        </div>
      </div>
    </div>

    <div class="col-md-4 col-xl-3">
      <div class="card bg-c-yellow order-card">
        <div class="card-block">
          <h6 class="m-b-20">Total clientes</h6>
          <h2 class="text-right">
            <i class="fa fa-user-plus f-left" aria-hidden="true"></i>
            <span class="p-3">0</span>
        </h2>
          <p class="m-b-0">Vér detalle<span class="f-right"></span></p>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-xl-3">
      <div class="card bg-c-pink order-card">
        <div class="card-block">
          <h6 class="m-b-20">Total sucursales</h6>
          <h2 class="text-right">
            <i class="fa fa-home f-left" aria-hidden="true"></i>
            <span class="p-3">0</span></h2>
          <p class="m-b-0">Vér detalle<span class="f-right"></span></p>
        </div>
      </div>
    </div>
  </div>



